import React, { useState, useEffect } from 'react'
import banner from './services/img/home-version-five-banner-side-img1.png'
import Services from './services/services'
import Aos from 'aos'
import AllService from "../pages/services/All-services"
import Other from '../pages/services/otherService'
import Team from "../pages/services/Team";
import Pricing from "../pages/services/PricingCard";
import Discover from "./services/img/home-five-digital-service-img.jpg"
import Business from './services/img/home.png'
import Growth from './services/img/home-five-digital-marketing-icon1.jpg'
import Idea from './services/img/home-five-digital-marketing-icon7.png'
import Certified from './services/img/home-five-digital-marketing-icon8.png'
import Face from './services/img/61b180a2805f1620c3234c45_Testimonial User.svg'
const Home = () => {
    const [service, setService] = useState([]);
    const [AllServices, setAllservices] = useState([])
    const [OtherServices, setOther] = useState([])
    const [Teams, setTeam] = useState([])
    const [pricing, setPricing] = useState([]);
    useEffect(() => {
        Aos.init({ duration: 2000 });
        setService(Services);
        setAllservices(AllService);
        setOther(Other);
        setTeam(Team);
        setPricing(Pricing);
    }, [])
    return (
        <div>
            <div className="banner">
            <div className="container flex banner-con">
            <div>
                <h1>Generating New <br/> Business Growth Plans</h1>
                <p>Donec sollicitudin magnaultric semper. Lorem ipsum dolor <br/>
sit amet consectetur.</p>
                <button className="btn-2">Get In Touch</button>
            </div>
                <img src={banner} alt="img" />
            </div>
            </div>
            

            {/* services */}
            <div className="services flex container">
                {service.map((curElm) => {
                    return (
                        <div data-aos="fade-up" className="animate service">
                            <div className="flex">
                                <h1 className="fs-5">{curElm.sname}</h1>
                                <img src={curElm.img} alt="img" />
                            </div>
                            <p className="fs-6 mt-3">{curElm.info}</p>
                        </div>
                    )
                })}
            </div>
            {/* end */}

            <div className="middle flex container mt-5">
                <img data-aos="fade-up" src={Discover} alt="img" className="container-sm" />
                <div className="stretegy ms-5">
                    <h1 className="fs-2">Brainstorming Business Ideas and Strategies</h1>
                    <p className="fs-4 mt-4">Risus commodo viverra maecnas quis cumsan lacus vel facilisis. Lorem sed ipsum.</p>
                    <p className="fs-6 mt-4">There are many variations of lorem passages of Lorem Ipsum available, but the majority have suffered. All the Lorem Ipsum generators on the Internet tend to repeat predefined.</p>
                    <button className="btn-2">Discover More</button>
                </div>
            </div>

                {/* services */}
            <div data-aos="fade-up" className="flex s-middle container">
                <div className="text">
                    <h1 className="fs-3">A Marketing Agency That Helps You Succeed</h1>
                    <p>There are many variations of lorem passages of Lorem Ipsum available, but the majority have suffered.
                        All the Lorem Ipsum generators on the Internet tend to repeat predefined.</p>
                    <button className="btn-2">Discover More</button>
                    <div className="flex  justify-content-end">
                        <div className="marketing service All-serve mt-5 d-flex flex-column align-items-center">
                            <img src={Growth} className="w-25" alt="img"/>
                            <h2 className="text-center fs-5 mt-4">Growth Calculation</h2>
                            <p className="text-center mt-2">There are many variations of lorem goe spassages of Lorem Ipsum available.</p>
                        </div>
                    </div>
                </div>
                <div className="planning text-center">
                    {AllServices.map((curElm) => {
                        return (
                            <>
                                <div className="service All-serve mb-3"><img src={curElm.img1} className="w-25" alt="img"/>
                                    <h2 className="text-center fs-5 mt-4">{curElm.name}</h2>
                                    <p className="text-center mt-2">{curElm.information}</p>
                                </div>
                            </>
                        )
                    })}
                </div>
                <div className="social text-center">
                    {OtherServices.map((curElm) => {
                        return (
                            <>
                                <div className="service All-serve mt-4"><img src={curElm.img} className="w-25" alt="img"/>
                                    <h2 className="text-center fs-5 mt-4">{curElm.name}</h2>
                                    <p className="text-center mt-2">{curElm.information}</p>
                                </div>
                            </>
                        )
                    })}
                </div>
            </div>


            <div data-aos="fade-up" className="flex container mt container-sm">
                <div>
                    <img src={Business} alt="img" className="container-sm"/>
                </div>
                <div className="m-5 text-1">
                    <h1 className="fs-3">Getting the Maximum Out of Any New Business or Company</h1>
                    <p className="mt-3">All the Lorem Ipsum generators on the Internet tend to repeat predefined. There are many variations of lorem passages.</p>

                    <div className="flex">
                        <img src={Idea} className="w-25" alt="img"/>
                        <div className="ms-4">
                            <h1 className="fs-4">Innovative Ideas</h1>
                            <p className="fs-6">Lorem Ipsum available internet tend to repeat predefined.
                                All the Lorem Ipsum generators on the top.</p>
                        </div>
                    </div>
                    <div className="flex">
                        <img src={Certified} alt="img" />
                        <div className="ms-4">
                            <h1 className="fs-4">Innovative Ideas</h1>
                            <p className="fs-6">Lorem Ipsum available internet tend to repeat predefined.
                                All the Lorem Ipsum generators on the top.</p>
                        </div>
                    </div>
                </div>
            </div>


            {/* counter */}
            <div className="counter flex">
                <div className="container flex text-center">
                    <div><h1>2,531</h1><p>Projects Executed</p></div><div className="v-line"></div>
                    <div><h1>8,960</h1><p>Global Presence</p></div><div className="v-line"></div>
                    <div><h1>250</h1><p>Happy Clients</p></div><div className="v-line"></div>
                    <div><h1>3697</h1><p>Recognition</p></div>
                </div>
            </div>


            {/* Team  */}
            <div data-aos="fade-up" className="container text-center mt-5">
                <h1 className="fs-2">Our Proficient Team</h1>
                <p className="mt-4 text-muted">There are many variations of lorem passages of Lorem Ipsum available, but the majority have suffered.
                        <br />All the Lorem Ipsum generators on the Internet tend to repeat predefined.</p>
                
                <div className="flex">
                    {Teams.map((curElm) => {
                        return (
                            <>
                                <div className="team">
                                    <img src={Face} className="teams" alt="img"/>
                                    <h3 className="mt-3">{curElm.Name}</h3>
                                    <p>{curElm.Designation}</p>
                                </div>
                            </>
                        )
                    })}
                </div>
            </div>

            {/* pricing */}
            <div className="container text-center mt-5">
                <h1 className="fs-2 mt-5">Our Pricing Plans</h1>
                <p className="mt-4 text-muted">There are many variations of lorem passages of Lorem Ipsum available, but the majority have suffered.
                        <br />All the Lorem Ipsum generators on the Internet tend to repeat predefined.</p>

                <div data-aos="fade-up" className="flex cards">
                    {pricing.map((curElm) => {
                        return (
                            <>
                                <div className="plan-card text-muted" style={curElm.bgcolor}>
                                    <h2 className="fs-4 text-black">{curElm.plan}</h2>
                                    <span className="fs-1 text-black mt-5">{curElm.amount}</span>
                                    <span className="ms-2">{curElm.month}</span>
                                    <p className="mt-3">{curElm.keys}</p>
                                    <p>{curElm.ranking}</p>
                                    <p>{curElm.analysis}</p>
                                    <p>{curElm.keywords}</p>
                                    <p>{curElm.content}</p>
                                    <button className="btn-2">Select Plan</button>
                                </div>
                            </>
                        )
                    })}
                </div>
            </div>

            <div className="bg-primary mt-5 text-center contact">
                <h2>Getting On-Board Our Business Game-Plan</h2>
                <p>We make the impossible happen in a wink</p>
                <button className="bg-white text-primary btn-2">Contact Us Today</button>
            </div>




        </div>
    )
}

export default Home
