const mystyle = {
    backgroundColor: "blue",
};
const Pricing = [{
    plan:"Basic Plan",
    amount:"$199",
    month:"/per Month",
    keys:"10 Key Words Optimized",
    ranking:"3 Top 10 Ranking Guarantee",
    analysis:"Web site Analysis",
    keywords:"Keyword Research and Analysis",
    content:"Content Optimization"
},
{
    plan:"Basic Plan",
    amount:"$299",
    month:"/per Month",
    bgcolor:{mystyle},
    keys:"10 Key Words Optimized",
    ranking:"3 Top 10 Ranking Guarantee",
    analysis:"Web site Analysis",
    keywords:"Keyword Research and Analysis",
    content:"Content Optimization"
},
{
    plan:"Basic Plan",
    amount:"$499",
    month:"/per Month",
    keys:"10 Key Words Optimized",
    ranking:"3 Top 10 Ranking Guarantee",
    analysis:"Web site Analysis",
    keywords:"Keyword Research and Analysis",
    content:"Content Optimization"
},
]
export default Pricing