import React from 'react'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom'
import logo from './pages/services/img/New-Year.png';
import Home from './pages/Home'
import About from './pages/About'
const Header = () => {
    const linkStyle = {
        textDecoration: "none",
        color: 'white'
    };
    return (
        <Router>
            <div className="nav-main">

                <div className="navbar container flex">
                    <div className="logo">
                        <img src={logo} alt="logo" />
                    </div>
                    <ul className="flex">
                        <li><Link to="/" style={linkStyle}>Home</Link></li>
                        <li><Link to="/about" style={linkStyle}>About</Link></li>
                        <li><Link style={linkStyle}>Services</Link></li>
                        <li><Link style={linkStyle}>Blog</Link></li>
                        <li><Link style={linkStyle}>Contact Us</Link></li>
                    </ul>
                </div>

                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/about" element={<About />} />
                </Routes>

            </div>

            {/* footer */}
        <footer className="footer">
            <div className="container d-flex justify-content-between p-5">

                <div className="text-white w-25">
                    <h1>About us</h1>
                    <p>There are many variations of lorem spassages of
    Lorem Ipsum available <br /> internet tend to repeat predefined.</p>
                    <img src={logo} className="logo-img w-50" />
                </div>

                <div className="">
                    <h1>Important Links</h1>
                    <ul className="d-flex flex-column">
                        <li><Link to="/" style={linkStyle}>Home</Link></li>
                        <li><Link to="/about" style={linkStyle}>About</Link></li>
                        <li><Link style={linkStyle}>Services</Link></li>
                        <li><Link style={linkStyle}>Blog</Link></li>
                        <li><Link style={linkStyle}>Contact Us</Link></li>
                    </ul>
                </div>

                <div>
                    <h1>Featured Services</h1>
                    <ul>
                        <li>Searh Engine Optimizaion</li>
                        <li>Link Building</li>
                        <li>Keyword Targeting</li>
                        <li>Web Analytics</li>
                        <li>Email Marketing</li>
                    </ul>
                </div>
                <div className="text-white">
                    <h1>Contact Us</h1>
                    <p>Office Address 121 King St, <br/> Melbourne VIC 3000, Australia</p>

                    <p>Phone: (888)123-4587</p>
                    <p>Email: info@admin.com</p>
                </div>
            </div>
                <hr className="hr"/>
                <div className="text-white text-center pb-2">&#169; Majestic Technisoft 2021</div>
            </footer>
        </Router>
    )
}

export default Header
