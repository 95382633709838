const OtherService =[
    
        {
            name:"Social Media",
            img1:'https://ryse.radiantthemes.com/wp-content/uploads/2019/06/home-five-digital-marketing-icon5.jpg',
            information:"There are many variations of lorem goe spassages of Lorem Ipsum available."
        },
        {
            name:"Customer Support",
            img1:"https://ryse.radiantthemes.com/wp-content/uploads/2019/06/home-five-digital-marketing-icon6.jpg",
            information:"There are many variations of lorem goe spassages of Lorem Ipsum available."
        },
    
    
]
export default OtherService;