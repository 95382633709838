const AllServices = [
    {
        name:"Digital Marketing",
        img1:"https://ryse.radiantthemes.com/wp-content/uploads/2019/06/home-five-digital-marketing-icon2-1.jpg",
        information:"There are many variations of lorem goe spassages of Lorem Ipsum available."
    },
    {
        name:"social marketing",
        img1:"https://ryse.radiantthemes.com/wp-content/uploads/2019/06/home-five-digital-marketing-icon3.jpg",
        information:"There are many variations of lorem goe spassages of Lorem Ipsum available."
    },
    {
        name:"Digital Marketing",
        img1:"https://ryse.radiantthemes.com/wp-content/uploads/2019/06/home-five-digital-marketing-icon4.jpg",
        information:"There are many variations of lorem goe spassages of Lorem Ipsum available."
    },
]
export default AllServices;