import React from 'react'
import about from './services/img/about.png'
import Creativemind from "./services/img/about-dynamic-team-im.jpg"
const About = () => {
  return (
    <div>
      <div className="bg-primary">
      <div className="container about-banner flex text-white">
        <div className="text-white lh-lg"><h1>About Company</h1>
            <p>Donec sollicitudin magnaultric semper. Lorem ipsum dolor sit <br/> amet consectetur.</p>
        </div>
        <img src={about} alt="about"/>
      </div>
      </div>

      <div className="container flex pt-5 about-team">
          <img src={Creativemind} alt="img"/>
        <div className="">
            <h1 className="fs-2">A Bunch of Enthusiastic & <br/> Creative Minds</h1>
            <hr className="divider"/>
            <p className="text-muted mt-4">Finalizing new corporate ideas, game-plans and strategies for <br/> a firm.</p>
              <ul className="text-muted lh-lg">
                <li>An array of business formulation strategies</li>
                <li>Revenue generation and user engagement plans</li>
                <li>Charting company growth via new metrics</li>
                <li>Establishing a strong foothold in the industry</li>
              </ul>    
        </div>
      </div>
      
    </div>
  )
}

export default About
