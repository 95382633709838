const Team =[{
    Name:"Karen Moreo",
    Designation:"Designer",
    Img:""
},{
    Name:"Karen Hart",
    Designation:"Developer",
    Img:""
},
{
    Name:"Karen Mars",
    Designation:"Sr. Developer",
    Img:""
},
{
    Name:"Karen Ellen",
    Designation:"Animator",
    Img:""
}
]
export default Team