import logo from './logo.svg';
import './App.css';
import './css/style.css';
import Header from './components/header';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Responsive.css';


function App() {
  return (
    <div className="App">

      <Header />


    </div>
  );
}

export default App;
