const Services = [{
             sname:"Digital Marketing & Branding",
            img:"https://ryse.radiantthemes.com/wp-content/uploads/2019/06/home-five-service-icon1.jpg",
            info:"There are many variations of lorem goe spassages of Lorem Ipsum available."
    },
    {
        sname:"Social Media Marketing",
            img:"https://ryse.radiantthemes.com/wp-content/uploads/2019/06/home-five-service-icon2.jpg",
            info:"There are many variations of lorem goe spassages of Lorem Ipsum available."
    },
    {
        sname:"Search Engine Optimization",
            img:"https://ryse.radiantthemes.com/wp-content/uploads/2019/06/home-five-service-icon3.jpg",
            info:"There are many variations of lorem goe spassages of Lorem Ipsum available."
    },
    
]

export default Services

